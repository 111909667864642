@import "../../sass/vars";

.research {
	margin-top: 100px;
	@media only screen and (max-width: 56.25em) {
		margin-top: 80px;
		margin-top: 0;
	}
	strong {
		display: block;
		margin-bottom: 20px;
		margin-top: 80px;
	}

	.content {
		position: relative;
		text-align: left;
		padding-bottom: 80px;
		padding-top: 40px;
		min-height: 60vh;
		max-width: $max-width;
		margin: 0 auto;

		@media only screen and (max-width: 56.25em) {
			padding-top: 0px;
			padding-bottom: 20px;
		}

		.contentInner {
			p {
				margin-bottom: 40px;
				a {
					color: $pink;
					text-decoration: none;
					&:hover {
						text-decoration: underline !important;
					}
				}
			}
		}
	}
}
