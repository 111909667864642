@import "../../../sass/vars";

.intro {
	min-height: 600px;
	padding: 100px 0 0 0;
	background-image: url(../../../assets/research/researchback.png);
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	position: relative;

	@media only screen and (max-width: 56.25em) {
		padding: 0 20px;
		display: flex;
		/* align-items: center; */
		background-position: bottom right;
	}

	.content {
		max-width: $research-max-width;
		margin: 0 auto;
		position: relative;

		& > * {
			margin: 0 auto 40px auto;
		}
		@media only screen and (max-width: 56.25em) {
			margin-top: 120px;
		}
	}
}

@keyframes fadeInBack {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
