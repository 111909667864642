//__Colors
$black: #0f2841;
$newBlack: #0e1c32;
$grey: #6b6b6b;
$white: white;
$white-back: #e7e9ec;

$blue: #00519a;
$blue-border: #b2cae0;
$blue-back: #e5edf4;
$blue-dark: #0e1c32;

$green: #3cbf71;
$green-border: #cdedd7;
$green-back: #eef8f2;

$pink: #e52e6a;
$pink-border: #f9c6d6;
$pink-back: #fdecf1;

$yellow: #ffc100;
$yellow-border: #ffecb2;
$yellow-back: #fff8e5;
$yellow-dark: #ffc100;

//__Others
$side-nav-z-index: 99;
$nav-z-index: 101;
$highest-z: 100;
$overlay-z-index: 102;
$inside-post-z-index: 103;
$transition: 0.3s;
$max-width: 1400px;
$content-max-width: 1000px;

//__Research
$research-mb: 20px;
$research-new-section: 60px;
$research-max-width: 690px;
