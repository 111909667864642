@import "../../../sass/vars";

.header {
	width: 100%;
	min-height: 100vh;
	position: relative;
	display: grid;
	grid-template-rows: 16vh max-content 1fr max-content 50px;
	overflow: hidden;
	background: radial-gradient(
		circle at 90% 70%,
		$blue-border 10%,
		$green-border 70%
	);
	border-top: 1px solid $green;
	@media only screen and (max-width: 37.5em) {
		grid-template-rows: 80px minmax(max-content, auto) max-content 20px;
	}

	@media only screen and (max-width: 22.5em) {
		overflow-y: hidden;
		max-height: 100vh;
	}

	.leavesWrapper {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		max-width: 1420px;
		width: calc(100% + 20px);
		display: flex;
		align-items: center;
		overflow: hidden;

		.sideLeaves {
			height: 260px;
			transform: translateY(-50px);
		}
		@media (max-width: 900px) {
			display: none;
		}
	}

	.lottieOverlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;

		@media only screen and (max-width: 56.25em) {
			background-color: rgba($white, 0.8);
		}
	}

	.imgContainer {
		overflow: hidden;
		width: 100vw;
		height: 100vh;
		svg {
			height: 1000px !important;
			width: 1000px !important;
			max-width: 60vw;
			max-height: 110vh;
			right: -290px;
			bottom: -140px;
			position: absolute;

			@media (max-height: 800px) {
				height: 800px !important;
				right: -240px;
				bottom: -60px;
			}
			@media (max-width: 1100px) {
				height: 800px !important;
				right: -240px;
				bottom: -60px;
			}
			@media only screen and (max-width: 56.25em) {
				max-width: unset;
				max-height: unset;
			}
		}
	}
	//__Heading
	.headingWrapper {
		max-width: $max-width;
		width: 100%;
		z-index: 1;
		grid-row: 2 / 3;
		grid-column: 1 / -1;
		justify-self: center;
		padding: 20px 60px;
		box-sizing: border-box;

		@media only screen and (max-width: 56.25em) {
			padding: 0 30px;
		}

		@media only screen and (max-width: 37.5em) {
			align-self: center;
			padding: 80px 10px;
		}
		@media only screen and (max-width: 22.5em) {
			padding: 20px 10px 0px 10px;
		}

		.heading {
			color: $blue;
			margin-bottom: 0;
			/* text-shadow: 0 0 5px white; */

			.span {
				color: $green;
			}
		}

		/* .quote {
			text-shadow: 0 0 2px $white;
		} */
	}

	//__Milestone Slider
	.sliderWrapper {
		grid-row: 4 / 5;
		grid-column: 1 / -1;
		z-index: 1;
		position: relative;
		overflow-y: hidden;

		@media only screen and (max-width: 56.25em) {
			overflow-y: visible;
		}

		@media only screen and (min-width: 56.25em) {
			&:hover + .progressBar {
				opacity: 1;
			}
		}
		@media only screen and (max-width: 37.5em) {
			grid-row: 3 / 4;
		}

		//__Slider
		.sliderContainer {
			z-index: 1;
			display: grid;
			grid-template-columns: 80px 1fr 80px;
			align-items: center;
			transition: $transition;
			overflow-x: hidden;
			transform: translateY(20px);

			@media only screen and (max-width: 56.25em) {
				overflow-x: visible;

				::-webkit-scrollbar {
					width: 0px;
					background: transparent;
				}
			}
			@media only screen and (min-width: 56.25em) {
				&:hover .left {
					transform: translateX(10px) translateY(-60%);
				}

				&:hover .right {
					transform: translateX(-10px) translateY(-60%);
				}
			}

			.timeLine {
				grid-column: 1 / -1;
				grid-row: 1 / -1;

				// border: 1px solid red;

				overflow-x: scroll;
				display: grid;
				grid-auto-columns: 20vw;
				grid-template-rows: 3px 1fr;
				padding: 62px 0 40px 0;
				will-change: transform;
				animation: initialScroll 1s forwards ease-in-out;

				@media only screen and (max-width: 56.25em) {
					grid-auto-columns: 33.3vw;
				}
				@media only screen and (max-width: 37.5em) {
					grid-auto-columns: 50vw;
				}

				.line {
					grid-row: 1 / 2;
					//border-bottom: 3px dotted rgba($pink, 0.5);
					transform: translateY(-20px);
				}
			}

			.left {
				height: 80px;
				grid-column: 1 / 2;
				grid-row: 1 / -1;
				background-image: linear-gradient(
					to right,
					$white,
					rgba($white, 0.5)
				);
				border: 1px solid $blue;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $blue;

				transition: $transition;
				transform: translateX(-100%);
				cursor: pointer;
				border-radius: 80px;

				@media only screen and (max-width: 56.25em) {
					transform: translateX(0);
					position: absolute;
					bottom: 0px;
					left: 20px;
					border-radius: 60px;
					height: auto;

					padding: 6px;
				}

				&:hover {
					border: 1px solid $pink;
				}

				&:hover svg {
					transform: translateX(-10px);
					color: $pink;

					@media only screen and (max-width: 56.25em) {
						transform: translateX(-2px);
					}
				}

				svg {
					transition: $transition;
				}
			}

			.right {
				height: 80px;
				grid-column: 3 / -1;
				grid-row: 1 / -1;
				background-image: linear-gradient(
					to left,
					$white,
					rgba($white, 0.5)
				);
				border: 1px solid $blue;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $blue;
				transform: translateX(100%);
				transition: $transition;
				cursor: pointer;
				border-radius: 80px;

				@media only screen and (max-width: 56.25em) {
					transform: translateX(0);
					position: absolute;
					bottom: 0px;
					right: 20px;
					border-radius: 60px;
					height: auto;

					padding: 6px;
				}

				&:hover {
					border: 1px solid $pink;
				}

				&:hover svg {
					transform: translateX(10px);
					color: $pink;

					@media only screen and (max-width: 56.25em) {
						transform: translateX(2px);
					}
				}

				svg {
					transition: $transition;
				}
			}
		}
	}

	// PROGRESS BAR
	.progressBar {
		grid-row: 5 / 6;
		grid-column: 1 / -1;
		align-self: center;
		z-index: 1;
		width: 40%;
		height: 1px;
		background-color: $blue;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: $transition;

		@media only screen and (max-width: 56.25em) {
			opacity: 1;
		}
		@media only screen and (max-width: 37.5em) {
			grid-row: 4 / 5;
		}

		.bar {
			width: 2px;
			background-color: $green;
			position: absolute;
			height: 20px;
			transition: 0.1s linear;
			left: -2px;
			bottom: -10px;
		}
	}

	.sliderBackground {
		grid-row: 4 / -1;
		grid-column: 1 / -1;
		height: calc(100% - 60px);
		align-self: end;
		background-color: $white;
		z-index: 0;

		@media only screen and (max-width: 37.5em) {
			grid-row: 3 / 6;
		}
	}
}

.inactiveArrow {
	opacity: 0.3;
	pointer-events: none;
}

@keyframes initialScroll {
	0% {
		transform: translateX(-30vw);
		opacity: 0;
	}

	100% {
		transform: translateX(0px);
		opacity: 1;
	}
}
