@import "../../../sass/vars";
@import "../../../sass/mixins";

.globals {
	@include researchSection;

	& > * {
		margin-bottom: $research-mb;
	}

	.heading {
		max-width: $research-max-width;
	}

	.desc {
		max-width: $research-max-width;
	}
	.chart {
		margin-bottom: 40px;
		p {
			font-size: 12px;
		}
	}
	.quote {
		position: relative;
		:after {
			content: ",,";
			left: -20px;
			top: -30px;
			position: absolute;
			font-size: 118px;
			color: $blue-back;
			line-height: 0;
			z-index: -1;
			font-family: sans-serif;
			letter-spacing: -10px;
		}
	}

	.factsWrapper {
		max-width: $research-max-width;

		.fact {
			display: flex;
			align-items: center;
			margin-bottom: $research-mb;
			p,
			h2 {
				margin: 0;
			}

			@media only screen and (max-width: 25em) {
				flex-direction: column;
				align-items: unset;
			}

			.factNumber {
				color: $pink;
				margin-right: 20px;
			}

			.good {
				color: $green;
			}

			.invisible {
				color: transparent;
				@media only screen and (max-width: 25em) {
					display: none;
				}
			}
		}

		.lastFact {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@media only screen and (max-width: 25em) {
				flex-direction: row;
			}

			& > * {
				width: 44%;
			}

			.leftNumber {
				color: $pink;
				margin-bottom: $research-mb;
			}

			.rightNumber {
				color: $green;
				margin-bottom: $research-mb;
			}
		}
	}

	.nextPage {
		@include researchNextPageBtn;
	}
}
