@import "../../../../sass/vars";

.bubble{
    width: 80%;
    justify-self: center;
    grid-row: 2 / 3;
    border-top: 1px solid transparent;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: all $transition;

    &:hover {
        border-top: 1px solid $green;
    }

    &::after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $pink;
        position: absolute;
        border: 1px solid $white;
        border-radius: 50%;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        transition: $transition;
    }
}

.bubbleToday {
    width: 80%;
    justify-self: center;
    grid-row: 2 / 3;
    border-top: 1px solid transparent;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    color: $pink;
    cursor: pointer;
    transition: all $transition;

    &:hover {
        border-top: 1px solid $green;
    }

    &::after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $pink;
        position: absolute;
        border: 1px solid $pink;
        border-radius: 50%;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        transition: $transition;
    }

    &:before{
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        background-color: $pink;
        top: -34px;
        left: 50%;
        transform: translateX(-50%) scale(2);
        animation: pulsate 2s linear infinite;
    }
}

.mileStone {
    & h3,
    p {
        color: $blue;
    }

    &:hover:after {
        background-color: transparent;
        border: none;
    }

    &::after {
        border: none;
        background-color: transparent;
        border-radius: 0;
        background-image: url(../../../../assets/header/shield.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &::before {
        content: "";
        width: 26px;
        height: 26px;
        position: absolute;
        background-image: url(../../../../assets/header/shield.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: -38px;
        left: 50%;
        transform: translateX(-50%) scale(1);
        animation: glow 1s infinite;
    }
}

.today {
    cursor: unset;
    & h3,
    p {
        color: $blue;
    }

    &:hover{
        border-top: 1px solid transparent;
    }
    &:hover:after {
        background-color: transparent;
        border: none;
    }

    &::after {
        width: 26px;
        height: 26px;
        border: none;
        background-color: transparent;
        border-radius: 0;
        background-image: url(../../../../assets/header/today.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        left: 50%;
        top: -60px;
        transform: translateX(-50%);
        animation: down 1s infinite;
    }

    &::before {
        content: "";
        width: 26px;
        height: 26px;
        position: absolute;
        background-image: url(../../../../assets/header/today.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: -60px;
        left: 50%;
        transform: translateX(-50%) scale(1);
        animation: fadeOut 1s infinite;
    }
}

@keyframes glow {
    to {
        transform: translateX(-50%) scale(1.8);
        opacity: 0;
    }
}

@keyframes pulsate {
    0%{
        transform: translateX(-50%) scale(1);
        opacity: 0;
    }
    50%{
        transform: translateX(-50%) scale(1.8);
        opacity: 1;
    }
    100%{
        transform: translateX(-50%) scale(1);
        opacity: 0;
    }
}

@keyframes down {
    0%{
        transform: translate(-50%, 0);
    }
    50%{
      transform: translate(-50%, 50%)
    }
    100%{
       transform: translate(-50%, 0)
    }
}

@keyframes fadeOut {
    to{
        opacity: 0;
    }
}