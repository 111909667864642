@import "../../../sass/vars";

.sideNav {
  color: $black;
  text-align: left;
  position: sticky;
  top: 140px;
  max-width: 200px;
  height: 0;
  background-color: $white;
  z-index: $side-nav-z-index;
  transition: $transition;

  @media only screen and (max-width: 56.25em), screen and (max-height: 650px) {
    top: 120px;
  }

  @media only screen and (max-width: 56.25em) {
    width: 100%;
    top: 80px;
    max-width: unset;
  }
  @media only screen and (max-width: 37.5em) {
    top: 60px;
    p {
      font-size: 12px !important;
    }
  }

  .mobileWrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    @media only screen and (max-width: 56.25em) {
      flex-direction: row;
      box-shadow: 0 1px $blue-border;
    }
  }

  .item {
    padding: 15px 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: $white;
    position: relative;
    text-decoration: none;
    color: $blue;
    transition: $transition;
    flex-grow: 1;

    @media only screen and (max-width: 37.5em) {
      flex: 1;
    }

    @media only screen and (max-width: 56.25em),
      screen and (max-height: 760px) {
      padding: 15px 40px;
    }
    @media only screen and (max-width: 56.25em),
      screen and (max-height: 720px) {
      padding: 10px 40px;
      .icon {
        width: 30px !important;
        height: 30px !important;
      }
    }
    @media only screen and (max-width: 56.25em),
      screen and (max-height: 620px) {
      padding: 8px 40px;
    }

    &:after {
      content: "";
      height: 100%;
      width: 1px;
      background: $pink;
      position: absolute;
      top: 0;
      right: 0;
      transform: scaleY(0);
      transition: $transition;

      @media only screen and (max-width: 56.25em) {
        height: 1px;
        width: 100%;
        top: unset;
        bottom: 0;
        transform: scaleX(0);
      }
    }

    &:hover:after {
      @media only screen and (min-width: 56.25em) {
        transform: scaleY(1);
      }
    }

    @media only screen and (max-width: 56.25em) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
    }

    .icon {
      margin-right: 20px;
      width: 40px;
      height: 40px;

      @media only screen and (max-width: 56.25em) {
        margin-right: 0;
        margin-bottom: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .subNav {
    @media only screen and (max-width: 56.25em) {
      display: flex;
      position: absolute;
      z-index: $side-nav-z-index + 1;
      width: 100%;
      opacity: 0;
      transform: translateY(-50px);
      transition: $transition;
      pointer-events: none;

      & > * {
        &:not(:last-child) {
          flex: 1;
        }
      }
    }
    .subitem {
      box-sizing: border-box;
      padding: 20px 40px 20px 100px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: $white;
      position: relative;
      text-decoration: none;
      color: $blue;
      transition: $transition;
      @media only screen and (max-width: 56.25em),
        screen and (max-height: 760px) {
        padding: 15px 40px 15px 100px;
      }
      @media only screen and (max-width: 56.25em),
        screen and (max-height: 720px) {
        padding: 10px 40px 10px 100px;
        .icon {
          width: 30px !important;
          height: 30px !important;
        }
      }
      @media only screen and (max-width: 56.25em),
        screen and (max-height: 620px) {
        padding: 8px 40px 8px 100px;
      }

      @media only screen and (max-width: 56.25em) {
        height: auto;
        padding: 5px 0;
        flex-direction: column;
      }

      .link {
        margin-left: 20px;

        @media only screen and (max-width: 56.25em) {
          margin-left: 0;
        }
      }

      .icon {
        height: 20px;
        min-width: 20px;
        transition: $transition;

        @media only screen and (max-width: 56.25em) {
          height: 30px;
          min-width: 30px;
          margin-bottom: 10px;
        }
      }
      &:before {
        position: absolute;
        width: 5px;
        background-color: $green;
        top: 50%;
        transform: translateY(-50%);
        left: 60px;
        height: 2px;
        border-radius: 20px;
      }
    }
    .subNavClose {
      outline: none;
      color: $blue;
      background-color: $white;
      border: none;
      font-size: 50px;
      padding-right: 10px;
      line-height: 0;
      cursor: pointer;
      transition: $transition;

      &:hover {
        color: $pink;
      }

      @media only screen and (max-width: 400px) {
        font-size: 30px;
      }
    }
  }
  .subNavShow {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: auto;
  }
}

.noMarginTop {
  top: 0px;
}

.activeNavItem {
  color: $pink !important;
  &:after {
    transform: scaleY(1) !important;

    @media only screen and (max-width: 56.25em) {
      transform: scaleX(1) !important;
    }
  }
}
