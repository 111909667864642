.odometer {
	position: relative;
}
.odometer:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	height: 20px;
	background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
	top: 0;
}
.odometer:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	height: 20px;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	bottom: 0;
	z-index: 100;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
	display: block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	position: relative;
	height: 40px;
}
.odometer * {
	text-align: center !important;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
	text-align: left;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 40px;
	overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
	display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
	display: block;
	-webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-car .odometer-digit .odometer-value {
	display: block;
	-webkit-transform: translateZ(0);
	font-size: 26px;
	font-weight: 700;
	@media (max-width: 1000px) {
	}
}

.odometer.odometer-auto-theme
	.odometer-digit
	.odometer-value.odometer-last-value,
.odometer.odometer-theme-car
	.odometer-digit
	.odometer-value.odometer-last-value {
	position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
	-webkit-transition: -webkit-transform 3s ease-in-out;
	-moz-transition: -moz-transform 3s ease-in-out;
	-ms-transition: -ms-transform 3s ease-in-out;
	-o-transition: -o-transform 3s ease-in-out;
	transition: transform 3s ease-in-out;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
	.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up.odometer-animating
	.odometer-ribbon-inner {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
	.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
	.odometer-ribbon-inner {
	-webkit-transition: -webkit-transform 3s;
	-moz-transition: -moz-transform 3s;
	-ms-transition: -ms-transform 3s;
	-o-transition: -o-transform 3s;
	transition: transform 3s;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
	-moz-border-radius: 0.34em;
	-webkit-border-radius: 0.34em;
	border-radius: 0.34em;
	padding: 0.15em;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
	padding: 0 0.2em;
}

.odometer.odometer-auto-theme .odometer-digit:first-child,
.odometer.odometer-theme-car .odometer-digit:first-child {
	-moz-border-radius: 0.2em 0 0 0.2em;
	-webkit-border-radius: 0.2em;
	border-radius: 0.2em 0 0 0.2em;
}

.odometer.odometer-auto-theme .odometer-digit:last-child,
.odometer.odometer-theme-car .odometer-digit:last-child {
	-moz-border-radius: 0 0.2em 0.2em 0;
	-webkit-border-radius: 0;
	border-radius: 0 0.2em 0.2em 0;
	color: #000;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
	left: 0em;
}
.odometer-formatting-mark {
	padding: 2px;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
	.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
	.odometer-ribbon-inner {
	-webkit-transition-timing-function: linear;
	-moz-transition-timing-function: linear;
	-ms-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}
.odometer-digit-inner div {
	left: 0;
	right: 0;
}
.odometer-inside .odometer-digit .odometer-value {
	padding: 2px 0;
}
.odometer-inside .odometer-digit:first-of-type {
	display: none;
}
