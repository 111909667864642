@import "../../../sass/vars";
@import "../../../sass/mixins";

.goal {
	@include lpSection;

	background-color: $green-back;
	border-top: 1px solid $green-border;
	border-bottom: 1px solid $green-border;
	.contentWrapper {
		display: flex;
		margin: auto;
		justify-content: center;
		max-width: $max-width;

		@media only screen and (max-width: 56.25em) {
			flex-wrap: wrap;
			justify-content: center;
		}

		.lottie {
			padding: 0 20px;
			margin-right: 6vw;
			border-radius: 50%;
			background-color: $blue-back;
			border: 1px solid $blue-border;

			width: 320px;
			height: 320px;
			box-sizing: border-box;
			flex-shrink: 0;

			@media only screen and (max-width: 56.25em) {
				& svg {
					box-sizing: border-box;
					transform: none !important;
				}
			}

			@media only screen and (max-width: 31.25em) {
				width: 220px;
				height: 220px;
			}
		}

		.content {
			// width: 60%;
			max-width: 690px;
			text-align: left;

			@media only screen and (max-width: 56.25em) {
				width: 100%;
				text-align: center;
				margin-top: 40px;
			}

			.paragraph {
				margin-bottom: 20px;
			}
		}
	}
}
