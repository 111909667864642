html {
	font-size: 16px;
	line-height: 1.5;
}
* {
	margin: 0;
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	font-weight: 300;
}
a {
	font-size: 16px;
}
p {
	max-width: 690px;
}
h1,
h2,
h3 {
	line-height: 1.2;
	margin-bottom: 10px;
}
h1 {
	font-size: 68px;
	font-weight: 700;
	@media (max-width: 600px) {
		font-size: 36px;
	}
}
h2 {
	font-size: 68px;
	font-weight: 700;
	@media (max-width: 600px) {
		font-size: 36px;
	}
	span {
		font-size: 26px;
	}
}
h3 {
	font-size: 26px;
	font-weight: 700;
}
h4 {
	font-size: 26px;
	font-weight: 300;

	@media only screen and (max-width: 56.25em) {
		font-size: 20px;
	}

	@media only screen and (max-width: 37.5em) {
		font-size: 18px;
	}
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 12px;
	color: $blue;
}
.small {
	font-size: 12px;
}
ol,
ul {
	list-style: none;
}

button a {
	font-size: 16px;
}

body {
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $black;
}

.SocialMediaShareButton:focus {
	outline: none;
}

// stop page scroll when modal is opened
.scroll-lock {
	overflow: hidden;
}
