@import "../../../sass/vars";

.singleNews {
	text-align: left;
	animation: showNews $transition ease-out forwards;

	& > * {
		margin-bottom: 10px;
	}

	.img {
		width: 300px;
		height: 200px;

		@media only screen and (max-width: 50em) {
			width: 260px;
			height: 180px;
		}

		img {
			border: 1px solid $blue-border;
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}

	.date {
		color: $green;
	}

	.desc {
		line-height: 1.6;
	}

	.btn {
		border: 1px solid $pink;
		border-radius: 50px;
		transition: $transition;
		background-color: $white;

		a {
			display: block;
			padding: 5px 10px;
			color: $pink;
			text-decoration: none;
			transition: $transition;
		}

		&:hover {
			border: 1px solid $white;
			background-color: $pink;
		}

		&:hover a {
			color: $white;
		}
	}
}

@keyframes showNews {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}
