@import "../../sass/vars";

.news {
    margin: 140px auto 60px auto;
    max-width: $max-width;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 0 60px;

    @media only screen and (max-width: 50em) {
        padding: 0 30px;
    }

    @media only screen and (max-width: 37.5em) {
        grid-template-columns: 1fr;
        margin: 80px auto 60px auto;
    }

    .filters {
        grid-column: 1 / -1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @media only screen and (max-width: 43.75em) {
            justify-content: space-between;
        }
        @media only screen and (max-width: 37.5em) {
            justify-content: space-evenly;
        }

        .filter {
            display: inline-flex;
            align-items: center;
            padding: 15px 30px;
            border: 1px solid $pink;
            border-radius: 40px;
            margin: 10px;
            outline: none;
            background-color: $white;
            color: $pink;
            transition: $transition;
            cursor: pointer;
            text-transform: capitalize;

            &:hover {
                background-color: $pink;
                color: $white;
            }

            @media only screen and (max-width: 37.5em) {
                flex-direction: column;
                padding: 5px 20px;
            }

            & svg {
                margin-right: 10px;

                @media only screen and (max-width: 37.5em) {
                    margin-right: 0px;
                }
            }

            .filterText {
                @media only screen and (max-width: 37.5em) {
                    font-size: 12px;
                }
            }
        }

        .active {
            background-color: $pink;
            color: $white;
        }
    }

    .count {
        grid-column: 1 /-1;
        color: $blue;

        .clearFilters{
            margin-top: 10px;
            font-size: 16px;
            border: 1px solid $pink;
            background-color: $white;
            color: $pink;
            border-radius: 50px;
            padding: 5px 10px;
            font-weight: 100;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            transition: $transition;

            svg {
                margin-right: 5px;
            }

            &:hover{
                cursor: pointer;
                background-color: $pink;
                color: $white;

            }
        }
    }
}
