@import "../../sass/vars";

.contributors {
	margin-top: 100px;
	@media only screen and (max-width: 56.25em) {
		margin-top: 0;
	}
	.intro {
		background-image: url(../../assets/contributors/contributorsback.svg);
		background-size: cover;
		background-position: bottom;
		background-repeat: no-repeat;
		position: relative;
		min-height: 600px;
		padding: 100px 0 0 0;

		@media only screen and (max-width: 56.25em) {
			padding: 0 20px;
			display: flex;
			background-position: bottom right;
		}

		.introContent {
			position: relative;
			max-width: $research-max-width;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media only screen and (max-width: 56.25em) {
				margin-top: 120px;
			}

			& > * {
				margin-bottom: 40px;
			}

			.joinUsForm {
				display: flex;
				justify-content: center;
				margin-right: 0;

				@media only screen and (max-width: 37.5em) {
					flex-direction: column;
				}

				.joinBtn {
					font-size: inherit;
					display: inline-block;
					border: 1px solid $pink;
					background-color: $pink;
					border-radius: 40px;
					padding: 20px 60px;
					color: $white;
					cursor: pointer;
					transition: $transition;
					outline: none;
					z-index: 1;

					&:hover {
						background-color: $white;
						color: $pink;
						border: 1px solid $pink;
					}
				}

				.joinUsWrapper {
					input {
						width: 300px;
						border-radius: 40px 0 0 40px;
						padding: 20px;
						font-size: inherit;
						border: 1px solid $pink;
						border-right: none;
						background-color: $white;
						outline: none;
						transition: $transition;
						animation: fadeInSection $transition ease;

						&:focus {
							box-shadow: inset 0 0 5px $pink;
						}

						@media only screen and (max-width: 37.5em) {
							border-radius: 40px;
							border: 1px solid $pink;
						}
					}

					button {
						font-size: inherit;
						display: inline-block;
						border: 1px solid $pink;
						background-color: $pink;
						border-radius: 0 40px 40px 0;
						padding: 20px 60px;
						color: $white;
						cursor: pointer;
						transition: $transition;
						outline: none;
						z-index: 1;
						animation: fadeInSection $transition ease;

						&:hover {
							background-color: $white;
							color: $pink;
							border: 1px solid $pink;
						}

						@media only screen and (max-width: 37.5em) {
							border-radius: 40px;
							border: 1px solid $pink;
							align-self: center;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}

	.content {
		position: relative;
		max-width: $content-max-width;
		margin: 60px auto;
		padding: 20px;
		display: grid;
		grid-template-rows: repeat(3, auto);

		& h5 {
			color: $blue;
			font-weight: bold;
			border-bottom: 2px solid transparent;
			padding-bottom: 10px;
			padding-top: 20px;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
			}
		}

		.contentTop {
			grid-column: 1 / -1;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			border-bottom: 1px solid $blue-border;
			.typeWrapper {
				grid-column: 1 / -1;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				justify-content: center;
				align-items: flex-end;
				position: relative;

				@media only screen and (max-width: 768px) {
					border-bottom: 1px solid $blue-border;
					margin-bottom: 20px;
				}
				@media only screen and (min-width: 768px) {
					& + .typeContent {
						position: relative;
						:after {
							content: "";
							position: absolute;
							width: 1px;
							background: $blue-border;
							right: 0;
							top: 0;
							bottom: 0;
							height: 100%;
						}
					}
				}

				.type {
					cursor: pointer;
					margin: 0;
					padding-bottom: 10px;
					position: relative;
					@media only screen and (min-width: 768px) {
						border-bottom: 0;
						&:first-of-type:after {
							content: "";
							position: absolute;
							width: 1px;
							background: linear-gradient(
								rgba(178, 202, 224, 0),
								$blue-border
							);
							right: 0;
							top: 0;
							bottom: 0;
							height: 100%;
						}
					}

					@media only screen and (min-width: 768px) {
						pointer-events: none;
					}
				}

				.activeType {
					@media only screen and (max-width: 768px) {
						border-color: $blue;
					}
				}
			}

			.typeContent {
				@media only screen and (max-width: 768px) {
					grid-column: 1 / -1;
					width: 100%;
					animation: fadeInSection $transition ease;
				}

				.status {
					color: $green;
					font-weight: 500;
				}
			}
		}
		.cashierpayment .contentTop .typeWrapper .type {
			@media only screen and (min-width: 768px) {
				&:first-of-type:after {
					background-color: $blue-border;
				}
			}
		}

		.distribution {
			border-bottom: 1px solid $blue-border;
		}

		.network {
			border-bottom: 1px solid $blue-border;
		}

		.comingSoon {
			display: grid;
			grid-template-columns: repeat(2, auto);
			grid-gap: 20px;
			justify-content: center;
			padding: 20px;
		}

		.contributor {
			border: 1px solid $blue-border;
			display: inline-flex;
			padding: 0 20px;
			margin: 10px;
			height: 50px;
			width: 200px;
			box-sizing: border-box;
			border-radius: 100px;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: $transition;
			&:last-of-type {
				margin-bottom: 40px;
			}
			& img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				max-width: 90px;
				max-height: 40px;
			}

			&:hover {
				background-color: $blue-back;
			}
		}
	}
}

@keyframes fadeInSection {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
}
