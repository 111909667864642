@import "../../../sass/vars";
@import "../../../sass/mixins";

.info {
    @include lpSection;

    &::after {
        display: none;
    }

    .contentWrapper {
        max-width: $max-width;
        margin: 0 auto;

        .intro {
            width: 50%;
            margin: 0 auto 60px auto;

            @media only screen and (max-width: 56.25em) {
                width: 100%;
            }
        }

        .slider {
            width: 50%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @media only screen and (max-width: 68.75em) {
                width: 60%;
            }
            @media only screen and (max-width: 56.25em) {
                width: 80%;
            }
            @media only screen and (max-width: 37.5em) {
                width: 100%;
            }

            .slide {
                animation: appearSlide $transition forwards ease-in;
                margin-bottom: 20px;

                .icon {
                    height: 80px;
                    margin-bottom: 20px;
                }
                .slideDesc {
                    color: $blue;
                }
            }
            .arrow {
                padding: 40px;
                color: $pink;

                cursor: pointer;

                @media only screen and (max-width: 56.25em) {
                    padding: 20px;
                }

                &:hover svg {
                    transform: translateX(5px);
                }

                & svg {
                    transition: $transition;
                }
            }
            .inactiveArrow {
                opacity: 0.3;
                cursor: auto;

                &:hover svg {
                    transform: none;
                }
            }
        }

        .btn {
            display: inline-block;
            border: 1px solid $pink;
            background-color: $white;
            border-radius: 40px;
            padding: 20px 40px;
            color: $pink;

            cursor: pointer;
            transition: $transition;
            outline: none;
            text-decoration: none;

            margin-top: 20px;

            &:hover {
                background-color: $pink;
                color: $white;
                border: 1px solid $white;
            }

            @media only screen and (max-width: 50em) {
                padding: 10px 20px;
            }
        }
    }
}

@keyframes appearSlide {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
