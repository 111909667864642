@import "../../../../sass/vars";

.storePrinting {
	max-width: $research-max-width;
	animation: slideIn $transition forwards ease-in;
	margin-bottom: 40px;
	p {
		margin: 0 !important;
	}
	.navigation {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		@media only screen and (max-width: 37.5em) {
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.btn {
			border: 1px solid $green;
			min-height: 40px;
			min-width: 40px;
			border-radius: 40px;
			color: $green;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			@media only screen and (max-width: 37.5em) {
				margin: 0 10px;
				position: absolute;
				&:first-of-type {
					left: 0;
				}
				&:last-of-type {
					right: 0;
				}
			}
		}

		.inactiveBtn {
			opacity: 0.3;
			cursor: auto;
		}

		.heading {
			flex-grow: 1;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			position: relative;

			@media only screen and (max-width: 37.5em) {
				width: 100%;
				order: 1;
				justify-content: center;
			}

			@media only screen and (max-width: 25em) {
				flex-direction: column;
				margin-bottom: 20px;
			}

			.imgWrapper {
				padding: 0 20px;
				height: 140px;

				@media only screen and (max-width: 25em) {
					margin-bottom: 10px;
				}

				& img {
					height: 100%;
					width: 100%;
					min-width: 100px;
				}
			}

			.receiptsAmount {
				padding: 0 20px;
				min-width: 290px;

				@media only screen and (max-width: 37.5em) {
					padding: 0 10px;
					min-width: 180px;
				}

				& span {
				}
			}
		}
	}

	.amounts {
		display: flex;

		@media only screen and (max-width: 37.5em) {
			flex-wrap: wrap;
		}

		.amount {
			flex: 1;
			color: $blue;
			cursor: pointer;

			@media only screen and (max-width: 37.5em) {
				width: 80%;
				flex: unset;
				margin: auto;
				text-align: center;
			}
			@media only screen and (max-width: 25em) {
				width: 100%;
			}

			&:hover .icon svg {
				transform: translateY(4px);
			}

			.numbers {
			}

			.desc {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				@media only screen and (max-width: 37.5em) {
					flex-direction: column;
					align-items: flex-start;
				}

				.nok {
					border-right: 2px solid $blue-border;
					padding-right: 40px;

					@media only screen and (max-width: 37.5em) {
						border-right: unset;
						border-bottom: 2px solid $blue-border;
						padding-right: 0;
						padding-bottom: 10px;
						width: 100%;
					}
				}

				.icon {
					color: $pink;
					margin-left: 20px;

					@media only screen and (max-width: 37.5em) {
						margin-left: 0;
						margin-top: 10px;
						width: 100%;
						text-align: center;
					}

					& svg {
						transition: $transition;
					}
				}

				.activeIcon {
					&:hover svg {
						transform: rotate(-180deg);
					}

					& svg {
						transform: rotate(-180deg);
					}
				}

				.costs {
					margin-top: 20px;
					padding-right: 20px;
					max-height: 0;
					height: auto;
					overflow: hidden;
					transition: $transition;
					color: $black;
				}

				.activeCosts {
					max-height: 600px;
				}
			}
		}
	}

	.paperReceipts {
		.name {
			color: $pink;
		}
	}

	.digitalReceipts {
		.name {
			color: $green;
		}
	}
}

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
