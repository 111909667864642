@import "../../../sass/vars";
@import "../../../sass/mixins";
form {
	width: 100%;
	margin-right: 40px;
	margin-bottom: 40px;
}
.inputGroup {
	position: relative;
	input {
		width: 100%;
		font-size: 20px;
		font-weight: bold;
		padding: 13px 0px;
		display: block;
		border: none;
		border-bottom: 1px solid $pink;
		margin-top: 9px;
	}
	input:focus {
		outline: none;
	}
	input:focus ~ label,
	input:valid ~ label {
		top: 50px;
		font-size: 12px;
	}

	label {
		font-size: 20px;
		font-weight: light;
		position: absolute;
		pointer-events: none;
		left: 0;
		top: 10px;
		transition: 0.2s ease all;
		-moz-transition: 0.2s ease all;
		-webkit-transition: 0.2s ease all;
	}
}

.toxicity {
	@include researchSection;

	& > * {
		margin-bottom: $research-mb;
	}

	.list {
		padding-left: 20px;
		margin-bottom: 40px;
		max-width: $research-max-width;
		p {
			margin-bottom: 0 !important;
		}

		@media only screen and (max-width: 37.5em) {
			padding-left: 0;
		}

		.item {
			display: flex;

			margin-bottom: 40px;

			& img {
				margin-right: 20px;
			}

			.itemDesc {
				& span {
				}
			}
		}
	}

	.nextPage {
		@include researchNextPageBtn;
	}
}
