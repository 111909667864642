@import "../../../sass/vars";
@import "../../../sass/mixins";

.weWaste {
	display: flex;
	flex-wrap: wrap;
	max-width: 690px;
	margin: 0 auto;
	background-color: $white;
	@include lpSection;

	.heading {
		width: 100%;
		margin-bottom: 60px;

		@media only screen and (max-width: 37.5em) {
			margin-bottom: 40px;
		}
	}

	.wastePart {
		width: 33.3%;

		@media only screen and (max-width: 37.5em) {
			width: 100%;

			margin-bottom: 40px;
		}

		.icon {
			width: 40px;
			height: 40px;
		}
		.headingNature,
		.headingWater,
		.headingCO2 {
			position: relative;
			p {
				position: absolute;
				top: 53%;
				font-size: 26px;
				font-weight: bold;
				line-height: 0;
				left: 30px;
			}
		}
		.headingNature {
			span,
			p {
				color: $green !important;
			}
		}

		.headingWater {
			span,
			p {
				color: $blue !important;
			}
		}

		.headingCO2 {
			span,
			p {
				color: $grey !important;
			}
		}
	}

	.quote {
		margin-top: 60px;
		width: 100%;
		b {
			color: $pink;
		}
		@media only screen and (max-width: 37.5em) {
			margin: 0;
			margin-top: 20px;
		}
	}
	.small {
		font-size: 12px !important;
		margin: auto;
		margin-top: 40px;
		a {
			font-size: 12px !important;
			color: $pink;
		}
		text-align: center;
	}
}

@keyframes in {
	from {
		transform: translateY(-100%);
	}
}

@keyframes to {
	to {
		transform: translateY(100%);
	}
}

.countingNumber {
	animation: numberAnimate 1s forwards;
}

@keyframes numberAnimate {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
