@import "../../../sass/vars";
@import "../../../sass/mixins";

.economic {
	@include researchSection;

	& > * {
		margin-bottom: $research-mb;
	}

	//__Economic Content
	.economicContent {
		//__NUMBERS
		.numbers {
			margin-bottom: 40px;
			display: flex;

			@media only screen and (max-width: 37.5em) {
				flex-wrap: wrap;
			}

			.number {
				flex: 1;
				cursor: pointer;
				padding-right: 40px;

				@media only screen and (max-width: 37.5em) {
					width: 80%;
					flex: unset;
					margin: auto;
					text-align: center;
				}

				@media only screen and (max-width: 25em) {
					width: 100%;
				}

				&:hover .icon svg {
					transform: translateY(4px);
				}

				.price {
					color: $blue;
				}

				.textWrapper {
					margin-bottom: $research-mb;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.text {
						margin-bottom: 0;
						padding-right: 20px;
						padding-bottom: 10px;
						border-bottom: 1px solid $blue-border;
					}

					.icon {
						margin-left: 20px;
						color: $pink;

						transition: $transition;
						cursor: pointer;

						@media only screen and (max-width: 37.5em) {
							margin-left: 0;
							width: 100%;
							text-align: center;
						}

						& svg {
							transition: $transition;
						}
					}

					.activeIcon {
						&:hover svg {
							transform: rotate(-180deg);
						}

						& svg {
							transform: rotate(-180deg);
						}
					}

					.costs {
						opacity: 0;
						max-height: 0;
						height: auto;
						overflow: hidden;
						transform-origin: top;
						padding: 20px 0;
						p {
							margin: 0;
						}
						/* transition: $transition; */

						.cost {
							.costName {
								text-decoration: underline;
								margin: 10px 0;
							}

							.costText {
								margin-bottom: 5px;

								& span {
								}
							}
							.calc {
								margin-bottom: 10px;
								p {
									font-size: 12px;
								}
							}

							.expSumup {
								& span {
								}
							}
						}
					}

					.activeCosts {
						max-height: 1000px;
						opacity: 1;
					}
				}
			}
		}

		//__IMPACT
		.impact {
			& > * {
				margin-bottom: $research-mb;
			}

			.text {
				& span {
				}
			}
		}
	}

	.nextPage {
		@include researchNextPageBtn;
	}
}
