@import "../../sass/vars";

.contentWrapper {
  position: relative;
  z-index: 99;
  // width: 100%;
  background-color: $blue-dark;
  padding: 100px 60px;
  @media (max-width: 900px) {
    padding: 20px 20px 0px 20px;
  }
}

.wrapper {
  font-family: "JetBrains Mono", monospace;
  text-transform: uppercase;
  max-width: 1250px;
  margin: 0 auto;
  position: relative;
  color: $blue;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  z-index: $nav-z-index;

  h2 {
    color: $blue-border;
    font-size: 20px;
  }
  a {
    color: $blue-border;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 56.25em) {
    flex-direction: column;
    padding: 0px;
  }

  @media only screen and (max-width: 37.5em) {
    padding: 0px;
    z-index: 1;
  }

  .column1 {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .column2 {
    .iconWrapper {
      display: flex;
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        color: $white;
        margin-right: 20px;
        transition: $transition;

        &:hover {
          background-color: $blue-border;
          transform: scale(1.2);
        }
      }
    }
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .column3 {
    display: flex;
    align-items: flex-start;
    @media (max-width: 768px) {
      padding: 30px 0;
    }

    .detailsWrapper {
      p {
        color: $blue-border;
      }
    }
    .icon {
      height: 80px;
      margin-right: 10px;
      border-radius: 100%;
      border: 1px solid $blue-border;
    }
    .addressWrapper {
      display: flex;
      align-items: flex-start;
    }
  }
}

.leaves {
  width: 100%;
  margin-left: 10px;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
}

.copyright {
  max-width: 1250px;
  margin: auto;
  text-align: start;
  font-family: "JetBrains Mono", monospace;
  text-transform: uppercase;
  p {
    color: $blue-border;
    font-weight: 300;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 20px 0;
  }
}
