@import "../../../sass/vars";

.modalOverlay {
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	position: fixed;
	background-color: rgba(15, 40, 65, 0.5);
	overflow-y: scroll;
	z-index: 102;
	padding: 40px;
	@media only screen and (max-width: 600px) {
		padding: 20px 10px;
		padding-bottom: 80px;
		align-items: flex-start;
	}

	@media only screen and (max-height: 750px) {
		padding: 20px 10px;
		padding-bottom: 80px;
		align-items: flex-start;
	}

	.modalCloseBtn {
		z-index: 100;
		color: white;
		background-color: unset;
		border: none;
		position: fixed;
		right: 40px;
		top: 40px;
		font-size: 30px;
		font-weight: bold;
		cursor: pointer;
		outline: none;
		transition: $transition;

		@media only screen and (max-width: 56.25em) {
			display: none;
		}

		&:hover {
			color: $pink;
		}
	}

	.modalContent {
		border: 1px solid $yellow-dark;
		padding: 0 20px;
		border-radius: 20px;
		background-color: $white;
		max-width: 360px;
		display: flex;
		flex-direction: column;
		animation: modalFadeIn $transition ease;
		position: relative;
		margin: auto;
		overflow: hidden;

		.modalHeader {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;

			.logoWrapper {
				height: 96%;
				width: 100px;
				display: flex;
				align-items: center;

				.logoImg {
					object-fit: cover;
					max-height: 100%;
					width: 100%;
				}
			}

			.rightSideWrapper {
				display: flex;
				.dateWrapper {
					.joinedText {
						text-align: right;
						font-size: 12px;
						line-height: 1;
					}
					.joinedDate {
						font-weight: bold;
					}
				}

				.mobileCloseBtn {
					color: $pink;
					margin-left: 10px;
					background-color: unset;
					border: none;
					font-size: 30px;
					font-weight: bold;
					cursor: pointer;
					outline: none;
					margin-right: -10px;

					@media only screen and (min-width: 56.25em) {
						display: none;
					}
				}
			}
		}

		.photoContainer {
			position: relative;
			margin: 0 0 40px 0;
			width: 100%;

			.photoWrapper {
				border: 1px solid $yellow-dark;
				height: 200px;

				.photo {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			.status {
				font-size: 12px;
				white-space: nowrap;
				background-color: $black;
				color: $white;
				border: 1px solid $yellow-dark;
				padding: 10px 20px;
				border-radius: 40px;
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.iconsWrapper {
			margin-bottom: 40px;
			width: 100%;

			.iconsItem {
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-bottom: 20px;
				}

				.icon {
					margin-right: 20px;
					width: 30px;
					max-height: 40px;
				}
			}
		}

		.aboutWrapper {
			box-sizing: border-box;
			border-top: 1px solid $blue-border;
			background-color: $blue-back;
			width: calc(100% + 40px);
			padding: 20px;
			text-align: center;
			font-size: 12px;
			left: -20px;
			position: relative;
			& > * {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}

			.address,
			.link {
				font-size: inherit;
				display: block;
				color: $blue;
			}
		}
	}
}

@keyframes modalFadeIn {
	from {
		opacity: 0;
		transform: translateY(100px);
	}
}
