@import "../../sass/vars";

.nav {
    position: fixed;
    top: 0;
    height: 100px;
    width: 100vw;
    box-sizing: border-box;
    z-index: $nav-z-index;
    text-transform: capitalize;
    transition: $transition;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid transparent;

    @media only screen and (max-width: 56.25em) {
        background-color: $white;
        border-bottom: 1px solid $blue-border;
        height: 80px;
    }

    @media only screen and (max-width: 37.5em) {
        height: 60px;
    }

    .mobileZeiptIcon {
        position: fixed;
        height: 40px;
        left: 20px;
        top: 20px;

        @media only screen and (min-width: 56.25em) {
            display: none;
        }
        @media only screen and (max-width: 37.5em) {
            top: 10px;
        }
    }

    .content {
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: $transition;
        padding: 30px 60px;
        box-sizing: border-box;

        @media only screen and (max-width: 56.25em) {
            padding: 100px 0 0 20px;
            position: absolute;
            margin: 0;
            top: 0;
            right: 0;
            width: 200px;
            min-height: 100vh;
            box-sizing: border-box;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: $white;
            border-left: 1px solid $blue-border;
            transform: translateX(100%);
        }
    }

    .mobileContent {
        transform: translateX(0%);
    }

    .icon {
        height: 50px;
        display: inline-block;

        @media only screen and (max-width: 56.25em) {
            display: none;
        }
    }

    .navigationWrapper {
        display: flex;

        @media only screen and (max-width: 56.25em) {
            flex-direction: column;
        }

        .navigation {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 56.25em) {
                flex-direction: column;
                align-items: flex-start;
            }

            .navItem {
                margin-right: 40px;
                color: $blue;
                position: relative;
                cursor: pointer;
                text-decoration: none;

                @media only screen and (max-width: 56.25em) {
                    margin-right: 0px;
                    margin-bottom: 30px;
                }

                &:hover::after {
                    transform: scaleX(1);
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $green;
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    transform: scaleX(0);
                    transition: 0.3s ease;
                }
            }

            .active {
                color: $green;

                &::after {
                    transform: scaleX(1);
                }
            }
        }

        .langWrapper {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 56.25em) {
                justify-content: center;
            }

            .lang {
                cursor: pointer;
                height: 30px;
                opacity: 0.5;

                &:first-child {
                    margin-right: 10px;
                }
            }

            .activeLang {
                opacity: 1;
            }
        }
    }

    .mobileLogo {
        display: none;
        position: fixed;
        top: 30px;
        right: 0px;
        z-index: 2;

        @media only screen and (max-width: 56.25em) {
            display: inline-block;
            width: 60px;
            cursor: pointer;
        }

        @media only screen and (max-width: 37.5em) {
            top: 20px;
        }

        .logoLine1 {
            height: 3px;
            width: 28px;
            background-color: $blue;
            box-shadow: 0 0 2px 1px $white;
            transition: $transition;
        }

        .logoLine1Active {
            transform: rotate(45deg);
            transform-origin: left;
        }

        .logoLine2 {
            height: 3px;
            width: 25px;
            background-color: $blue;
            box-shadow: 0 0 2px 1px $white;
            margin-top: 5px;
            transition: $transition;
        }

        .logoLine2Active {
            width: 0px;
        }

        .logoLine3 {
            height: 3px;
            width: 28px;
            margin-top: 5px;
            box-shadow: 0 0 2px 1px $white;
            background-color: $blue;
            transition: $transition;
        }

        .logoLine3Active {
            margin-top: 9px;
            box-shadow: none;
            transform: rotate(-45deg);
            transform-origin: left;
        }
    }
}

.navScrolled {
    background-color: $white;
    border-bottom: 1px solid $blue-border;
}

.hiddenNav {
    transform: translateY(-100%);
}
