@import "../../../sass/vars";
@import "../../../sass/mixins";

.water {
    @include researchSection;

    & > * {
        margin-bottom: $research-mb;
    }

    .descWrapper {
        display: flex;
        p,
        h2 {
            margin-bottom: 0 !important;
        }

        @media only screen and (max-width: 37.5em) {
            flex-direction: column;
        }

        .tower {
            height: 100%;

            @media only screen and (max-width: 37.5em) {
                padding: 0;
                height: auto;
                align-self: center;
            }
        }

        .desc {
            width: 70%;
            box-sizing: border-box;
            cursor: pointer;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &:hover .icon svg {
                transform: translateY(4px);
            }

            @media only screen and (max-width: 37.5em) {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                width: 100%;
                text-align: center;
            }

            .descName {
                color: $pink;
            }

            .descNumber {
                color: $blue;
            }

            .descText {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                @media only screen and (max-width: 37.5em) {
                    flex-direction: column;
                }

                .descHeading {
                    border-right: 2px solid $blue-border;
                    padding-right: 40px;

                    @media only screen and (max-width: 37.5em) {
                        border-right: none;
                        border-bottom: 2px solid $blue-border;
                        padding-bottom: 10px;
                        padding-right: 0;
                    }
                }

                .icon {
                    padding: 0 40px;
                    display: flex;
                    justify-content: center;
                    color: $pink;
                    transition: $transition;
                    cursor: pointer;

                    @media only screen and (max-width: 37.5em) {
                        margin-top: 10px;
                    }

                    & svg {
                        transition: $transition;
                    }
                }

                .activeIcon {
                    &:hover svg {
                        transform: rotate(-180deg);
                    }

                    & svg {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .costs {
            max-height: 0;
            overflow: hidden;
            transform-origin: top;
            height: auto;
            transition: $transition;
        }

        .activeCosts {
            max-height: 600px;
        }
    }

    .nextPage {
        @include researchNextPageBtn;
    }
}
