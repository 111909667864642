@import "../../../sass/vars";
@import "../../../sass/mixins";

.trees {
	@include researchSection;

	& > * {
		margin-bottom: $research-mb;
	}

	.descWrapper {
		margin-left: 40px;
		@media only screen and (max-width: 37.5em) {
			margin-left: 0px;
		}
	}

	.numbersWrapper {
		display: flex;
		p {
			margin-bottom: 0 !important;
		}
		@media only screen and (max-width: 37.5em) {
			flex-wrap: wrap;
		}

		.numbers {
			cursor: pointer;
			display: flex;
			@media only screen and (max-width: 37.5em) {
				width: 80%;
				margin: auto;
				text-align: center;
				flex-direction: column;
			}

			@media only screen and (max-width: 25em) {
				width: 100%;
			}

			&:hover .icon svg {
				transform: translateY(4px);
			}

			& img {
				margin-bottom: $research-mb;
				height: 100px;
			}

			.namePaper {
				color: $pink;
			}

			.nameDigital {
				color: $green;
			}

			.amountWrapper {
				.amount {
					margin-bottom: 0;
					color: $blue;
				}

				.amountDesc {
					display: inline-block;
					border-right: 2px solid $blue-border;
					padding-right: 40px;
					@media only screen and (max-width: 37.5em) {
						display: block;
						border-right: none;
						border-bottom: 2px solid $blue-border;
						padding-bottom: 10px;
						padding-right: 0;
					}
				}

				.icon {
					display: inline-block;
					cursor: pointer;
					padding: 0 40px;
					color: $pink;
					cursor: pointer;

					@media only screen and (max-width: 37.5em) {
						display: block;
						padding-bottom: 0;
						text-align: center;
						margin-top: 10px;
					}

					& svg {
						transition: $transition;
					}
				}

				.activeIcon {
					&:hover svg {
						transform: rotate(-180deg);
					}

					& svg {
						transform: rotate(-180deg);
					}
				}

				.treesCut {
					margin-top: $research-mb;
					padding-right: 20px;
					max-height: 0;
					overflow: hidden;
					transition: $transition;
					color: $black;
					height: auto;
				}

				.activeTreesCut {
					max-height: 600px;
				}
			}
		}
	}

	.list {
		padding-left: 20px;
		p {
			margin-bottom: 0 !important;
		}
		@media only screen and (max-width: 37.5em) {
			padding-left: 0;
		}

		.item {
			display: flex;
			margin-bottom: 40px;
			align-items: center;

			.icon {
				margin-right: $research-mb;
				width: 22px;
				display: flex;
				align-items: center;
			}
		}
	}

	.nextPage {
		@include researchNextPageBtn;
		margin-right: 20px;
	}
}
