@import "../../../sass/vars";
@import "../../../sass/mixins";

.co2 {
    @include researchSection;

    & > * {
        margin-bottom: $research-mb;
    }

    .list {
        // max-width: $research-max-width;
        padding-left: 20px;

        @media only screen and (max-width: 37.5em) {
            padding-left: 0;
        }

        .item {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            p {
                margin-bottom: 0 !important;
            }

            .icon {
                width: 22px;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
