@import "../../../sass/vars";
@import "../../../sass/mixins";

.trees {
	@include researchSection;

	& > * {
		margin-bottom: $research-mb;
	}
	li {
		margin-bottom: 20px;
	}
	.nextPage {
		@include researchNextPageBtn;
	}
}
