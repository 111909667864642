@mixin lpSection {
	position: relative;
	padding: 100px 60px;

	@media only screen and (max-width: 56.25em) {
		padding: 80px 20px;
	}
}

@mixin researchNextPageBtn {
	display: inline-block;
	padding: 10px 20px;
	border-radius: 40px;
	background-color: $white;
	border: 1px solid $pink;
	cursor: pointer;
	text-decoration: none;
	color: $pink;
	margin-top: 10px;
	transition: $transition;

	&:hover {
		background-color: $pink;
		color: $white;
	}
}

@mixin researchSection {
	position: relative;
	margin: auto;
	max-width: 690px;
	animation: researchSectionFadeIn 0.7s forwards;

	@media only screen and (max-width: 68.75em) {
		margin-left: 240px;
	}

	@media only screen and (max-width: 56.25em) {
		margin: 120px auto 0 auto;
		padding: 0 20px;
	}
	@media only screen and (max-width: 37.5em) {
		margin: 120px auto 0 auto;
	}
}

@keyframes researchSectionFadeIn {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
