@import '../../../sass/vars';

.error{
  box-sizing: border-box;
  height: calc(100vh - 165px);
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 60px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 56.25em) {
    height: 100vh;
    padding: 0 20px;
    align-items: center;
  }

  @media only screen and (max-width: 350px) {
    padding-top: 80px;
  }

  .heading{
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(30px);
    z-index: 1;
    animation: fadeIn $transition ease-out 0.1s forwards;
  }
  .quote{
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(30px);
    z-index: 1;
    animation: fadeIn $transition ease-out 0.2s forwards;
  }
  .btn{
    text-decoration: none;
    color: $pink;
    font-weight: 500;
    border: 1px solid $pink;
    background-color: $white;
    border-radius: 100px;
    padding: 10px 30px;
    transition: $transition;
    opacity: 0;
    transform: translateY(30px);
    z-index: 1;
    animation: fadeIn $transition ease-out 0.2s forwards;

    &:hover{
      background-color: $pink;
      color: $white;
    }
  }

  .img{
    position: absolute;
    right: 40px;
    top: 50%;
    max-height: 700px;
    height: 100%;
    opacity: 0;
    transform: translateY(-45%);
    animation: fadeIn $transition ease-out forwards;

    @media only screen and (max-width: 56.25em) {
      position: relative;
      top: 20px;
      right: 0;
      height: 300px;
    }
  }
}

@keyframes fadeIn {
  to{
    opacity: 1;
    transform: translateY(-50%);
  }
}

.subError{
  max-height: 600px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  padding-left: 240px;

  @media only screen and (max-width: 56.25em) {
    margin-top: 80px;
    align-items: center;
    padding-left: 0;
    justify-content: center;
  }

  .heading{
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeIn $transition ease-in 0.2s forwards;
  }
  .quote{
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeIn $transition ease-in 0.3s forwards;
  }
  .btn{
    text-decoration: none;
    color: $pink;
    font-weight: 500;
    border: 1px solid $pink;
    background-color: $white;
    border-radius: 100px;
    padding: 10px 30px;
    transition: $transition;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeIn $transition ease-in 0.4s forwards;

    &:hover{
      background-color: $pink;
      color: $white;
    }
  }

  .img{
    position: absolute;
    right: 40px;
    top: 50%;
    max-height: 600px;
    height: 100%;
    opacity: 0;
    transform: translateY(-50%);
    animation: fadeIn $transition ease-in forwards;

    @media only screen and (max-width: 56.25em) {
      position: relative;
      right: 0;
      top: 20px;
      height: 200px;
    }
  }
}



