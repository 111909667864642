@import "../../../../sass/vars";

.postModalOverlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: rgba(15, 40, 65, 0.5);
	top: 0;
	left: 0;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	z-index: $overlay-z-index;

	@media only screen and (max-width: 600px) {
		padding: 20px 10px;
		align-items: flex-start;
	}

	@media only screen and (max-height: 750px) {
		padding: 20px 10px;
		align-items: flex-start;
	}

	.postModalContent {
		z-index: $inside-post-z-index;
		max-width: 830px;
		justify-self: center;
		background-color: $white;
		border-radius: 10px;
		padding: 40px;
		position: relative;
		display: flex;
		align-items: flex-start;
		text-align: left;
		overflow: hidden;
		animation: modalFadeIn $transition ease;

		@media only screen and (max-width: 56.25em) {
			max-width: 420px;
			padding: 20px;
			flex-direction: column;
		}

		.content {
			& > * {
				margin-bottom: 10px;

				@media only screen and (max-width: 56.25em) {
					margin-bottom: 10px;
				}
			}

			.date {
				color: $green;
			}

			.btn,
			.mobileCloseBtn {
				border: 1px solid $pink;
				padding: 10px 20px;
				border-radius: 50px;
				transition: $transition;
				background-color: $white;
				font-size: inherit;
				outline: none;
			}
			.btn {
				margin-right: 10px;
				&:hover {
					background-color: $pink;
				}
			}
			.btn {
				a,
				a:visited,
				a:active,
				a:focus {
					display: block;
					color: $pink;
					text-decoration: none;
					transition: $transition;
				}

				&:hover a {
					color: $white;
				}
			}

			.mobileCloseBtn {
				color: $white;
				background: $pink;
				cursor: pointer;

				&:hover {
					color: $pink;
					background-color: $white;
				}

				@media only screen and (min-width: 56.25em) {
					display: none;
				}
			}
		}

		.imgWrapper {
			flex-shrink: 0;
			flex-grow: 0;
			width: 260px;
			height: 200px;
			margin-right: 40px;

			@media only screen and (max-width: 56.25em) {
				height: 160px;
				width: 100%;
				text-align: center;
				margin-right: 0;
				margin-bottom: 10px;
			}

			.img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
.closeBtn {
	position: absolute;
	top: 40px;
	right: 40px;
	cursor: pointer;
	transition: $transition;
	font-size: 30px;
	color: $white;
	border: none;
	outline: none;
	background-color: transparent;

	&:hover {
		color: $pink;
	}

	@media only screen and (max-width: 56.25em) {
		display: none;
	}
}
@keyframes modalFadeIn {
	from {
		opacity: 0;
		transform: translateY(100px);
	}
}
